<template>
  <div v-if="space">
    <add-space-image
      v-if="showAddImage"
      :showModal="showAddImage"
      :meetingtypeId="meetingtypeId"
      :space="space"
      :onClickCancel="hideModal"
    />

    <delete-space-image
      v-if="showDeleteImage"
      :showModal="showDeleteImage"
      :image="selectedImage"
      :onClickCancel="hideModal"
    />

    <ui-section-header
      :showAdd="true"
      :addFunction="setShowAddImage"
    >
      <template v-slot:title>Images</template>
    </ui-section-header>

    <div>
      <div>
        <message
          v-if="defaultImage === null && nonDefaultImages.length > 0"
          :message="'No default images selected'"
        />

        <message
          v-if="defaultImage === null && nonDefaultImages.length === 0"
          :message="'No images added'"
        />

        <div
          v-if="defaultImage"
          class="card-content"
        >
          <div class="card is-small">
            <div class="card-content is-active">
              <figure class="image is-4by3 has-margin-bottom">
                <img :src="
                    $options.filters.getLocationImage(
                      defaultImage.Image,
                      space.LocationId
                    )
                  " />
              </figure>
              <div class="has-text-right">
                <a
                  @click="setShowDeleteImage(defaultImage)"
                  class="has-text-danger"
                >
                  <span class="icon">
                    <font-awesome-icon :icon="['fa', 'trash-alt']" />
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="columns">
        <div
          v-for="(image, index) in nonDefaultImages"
          :key="index"
          class="column card"
        >
          <div class="card-content">
            <ImageViewer
              :aspectRatio="4/3"
              type="location"
              :imageSrc="image.Image"
              :itemName="space.Name"
              :itemId="space.LocationId"
              :imageSize="640"
              class="is-4by3 mb-4"
            />
            <div
              v-if="!image.IsDefault"
              class="has-text-right"
            >
              <span>
                <a
                  @click="setImageAsDefault(image)"
                  class="has-margin-right is-size-7"
                >
                  <span class="icon is-small">
                    <font-awesome-icon :icon="['fa', 'star']" />
                  </span>
                  <span>Set as default</span>
                </a>
              </span>
              <span>
                <a
                  @click="setShowDeleteImage(image)"
                  class="has-text-danger is-size-7"
                >
                  <span class="icon is-small">
                    <font-awesome-icon :icon="['fa', 'trash-alt']" />
                  </span>
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapMutations, mapState } from 'vuex'
import spaceProvider from '@/providers/space'

const AddSpaceImage = () => import(/* webpackChunkName: "space-images" */ '@/components/Spaces/ModalAddSpaceImage')
const DeleteSpaceImage = () =>
  import(/* webpackChunkName: "space-images" */ '@/components/Spaces/ModalDeleteSpaceImage')
const Message = () => import(/* webpackChunkName: "space-images" */ '@/components/UI/Message')
const ImageViewer = () => import(/* webpackChunkName: "space-images" */ '@/components/UI/Components/ImageViewer')

export default {
  components: {
    'add-space-image': AddSpaceImage,
    'delete-space-image': DeleteSpaceImage,
    message: Message,
    ImageViewer
  },

  props: {
    meetingtypeId: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      selectedImage: null,
      showDeleteImage: false,
      showAddImage: false,
    }
  },

  computed: {
    ...mapState('spaceStore', ['space']),

    defaultImage() {
      if (this.space) {
        let images = this.space.Images.filter(
          (i) => i.MeetingtypeId === this.meetingtypeId && i.IsDefault === true
        )

        if (images.length > 0) {
          return images[0]
        }
      }
      return null
    },

    nonDefaultImages() {
      if (this.space) {
        return this.space.Images.filter(
          (i) => i.MeetingtypeId === this.meetingtypeId && i.IsDefault === false
        )
      }
      return []
    },
  },

  methods: {
    ...mapMutations('spaceStore', ['setSpace']),

    setImageAsDefault(image) {
      let self = this
      let mSpace = JSON.parse(JSON.stringify(self.space))

      let oldDefaultIndex = mSpace.Images.findIndex(
        (i) => i.MeetingtypeId === self.meetingtypeId && i.IsDefault
      )
      if (oldDefaultIndex > -1) {
        let oldDefaultImage = mSpace.Images[oldDefaultIndex]
        oldDefaultImage.IsDefault = false

        Vue.set(mSpace.Images, oldDefaultIndex, oldDefaultImage)
      }

      let newDefaultIndex = mSpace.Images.findIndex((i) => i.Id === image.Id)
      if (newDefaultIndex > -1) {
        let newDefaultImage = mSpace.Images[newDefaultIndex]
        newDefaultImage.IsDefault = true

        Vue.set(mSpace.Images, newDefaultIndex, newDefaultImage)
      }

      spaceProvider.methods
        .updateSpace(mSpace)
        .then((response) => {
          if (response.status === 200) {
            self.setSpace(response.data)

            setTimeout(() => {
              self.onClickCancel()
            }, 1500)
          }
        })
        .catch((error) => {
          //console.log(error.response)
          self.isSavingError = true
        })
        .finally(() => {
          self.isSaving = false
          self.isSavingSuccess = true
        })
    },

    setShowAddImage() {
      this.showAddImage = true
    },

    setShowDeleteImage(image) {
      this.selectedImage = image
      this.showDeleteImage = true
    },

    hideModal() {
      this.showDeleteImage = false
      this.showAddImage = false
    },
  },
}
</script>

<style></style>
